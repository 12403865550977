import React from 'react'
import PropTypes from 'prop-types'

type ComponentProps = {
  statusCode?: number
}

const ErrorPage = ({ statusCode }: ComponentProps) => {
  return (
    <p>
      {statusCode ? `An error ${statusCode} occurred on server` : 'An error occurred on client'}
    </p>
  )
}

ErrorPage.propTypes = {
  statusCode: PropTypes.number,
}

export default ErrorPage
